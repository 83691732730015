var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-inner"
  }, [_c('b-table', {
    ref: "table_docs",
    staticClass: "table-docs",
    attrs: {
      "id": "table-docs",
      "fields": _vm.fields,
      "items": _vm.items,
      "busy": _vm.table_is_busy,
      "responsive": true,
      "empty-filtered-text": "Нет документов",
      "show-empty": ""
    },
    on: {
      "row-clicked": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center mt-2"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "center bold mt-2"
        }, [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.getSelectAll
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        var _vm$getSelectedArr;

        return [_c('div', {
          staticClass: "b-statuses",
          class: {
            warning_confirm: data.item.status === 'confirm'
          }
        }), data.value ? _c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "checked": (_vm$getSelectedArr = _vm.getSelectedArr) === null || _vm$getSelectedArr === void 0 ? void 0 : _vm$getSelectedArr.some(function (el) {
              var _data$item;

              return el === (data === null || data === void 0 ? void 0 : (_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.id);
            })
          },
          on: {
            "change": function (val) {
              return _vm.setSelect(val, data.item.id);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(date)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-date"
        }, [_vm._v(_vm._s(data.item.number))]), _c('div', {
          staticClass: "b-date date mt-1 table-substr"
        }, [_vm._v(" " + _vm._s(data.item.date ? _vm.convertDate(data.item.date) : '') + " ")])];
      }
    }, {
      key: "cell(branch)",
      fn: function (data) {
        var _data$item$branch, _data$item$branch2, _data$item2, _data$item2$branch, _data$item2$branch$en, _data$item2$branch$en2, _data$item3, _data$item3$branch, _data$item3$branch$en, _data$item3$branch$en2, _data$item4, _data$item4$branch, _data$item4$branch$en, _data$item4$branch$en2, _data$item5, _data$item5$branch, _data$item5$branch$en, _data$item5$branch$en2;

        return [_c('div', {
          staticClass: "b-supplier name"
        }, [_vm._v(" " + _vm._s((_data$item$branch = data.item.branch) !== null && _data$item$branch !== void 0 && _data$item$branch.name ? (_data$item$branch2 = data.item.branch) === null || _data$item$branch2 === void 0 ? void 0 : _data$item$branch2.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item2 = data.item) !== null && _data$item2 !== void 0 && (_data$item2$branch = _data$item2.branch) !== null && _data$item2$branch !== void 0 && (_data$item2$branch$en = _data$item2$branch.entities) !== null && _data$item2$branch$en !== void 0 && (_data$item2$branch$en2 = _data$item2$branch$en[0]) !== null && _data$item2$branch$en2 !== void 0 && _data$item2$branch$en2.inn ? (_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : (_data$item3$branch = _data$item3.branch) === null || _data$item3$branch === void 0 ? void 0 : (_data$item3$branch$en = _data$item3$branch.entities) === null || _data$item3$branch$en === void 0 ? void 0 : (_data$item3$branch$en2 = _data$item3$branch$en[0]) === null || _data$item3$branch$en2 === void 0 ? void 0 : _data$item3$branch$en2.inn : '–') + "/ " + _vm._s((_data$item4 = data.item) !== null && _data$item4 !== void 0 && (_data$item4$branch = _data$item4.branch) !== null && _data$item4$branch !== void 0 && (_data$item4$branch$en = _data$item4$branch.entities) !== null && _data$item4$branch$en !== void 0 && (_data$item4$branch$en2 = _data$item4$branch$en[0]) !== null && _data$item4$branch$en2 !== void 0 && _data$item4$branch$en2.kpp ? (_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : (_data$item5$branch = _data$item5.branch) === null || _data$item5$branch === void 0 ? void 0 : (_data$item5$branch$en = _data$item5$branch.entities) === null || _data$item5$branch$en === void 0 ? void 0 : (_data$item5$branch$en2 = _data$item5$branch$en[0]) === null || _data$item5$branch$en2 === void 0 ? void 0 : _data$item5$branch$en2.kpp : '–') + " ")])])];
      }
    }, {
      key: "cell(targetBranch)",
      fn: function (data) {
        var _data$item$targetBran, _data$item$targetBran2, _data$item6, _data$item6$targetBra, _data$item6$targetBra2, _data$item6$targetBra3, _data$item7, _data$item7$targetBra, _data$item7$targetBra2, _data$item7$targetBra3, _data$item8, _data$item8$targetBra, _data$item8$targetBra2, _data$item8$targetBra3, _data$item9, _data$item9$targetBra, _data$item9$targetBra2, _data$item9$targetBra3;

        return [_c('div', {
          staticClass: "b-supplier name"
        }, [_vm._v(" " + _vm._s((_data$item$targetBran = data.item.targetBranch) !== null && _data$item$targetBran !== void 0 && _data$item$targetBran.name ? (_data$item$targetBran2 = data.item.targetBranch) === null || _data$item$targetBran2 === void 0 ? void 0 : _data$item$targetBran2.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item6 = data.item) !== null && _data$item6 !== void 0 && (_data$item6$targetBra = _data$item6.targetBranch) !== null && _data$item6$targetBra !== void 0 && (_data$item6$targetBra2 = _data$item6$targetBra.entities) !== null && _data$item6$targetBra2 !== void 0 && (_data$item6$targetBra3 = _data$item6$targetBra2[0]) !== null && _data$item6$targetBra3 !== void 0 && _data$item6$targetBra3.inn ? (_data$item7 = data.item) === null || _data$item7 === void 0 ? void 0 : (_data$item7$targetBra = _data$item7.targetBranch) === null || _data$item7$targetBra === void 0 ? void 0 : (_data$item7$targetBra2 = _data$item7$targetBra.entities) === null || _data$item7$targetBra2 === void 0 ? void 0 : (_data$item7$targetBra3 = _data$item7$targetBra2[0]) === null || _data$item7$targetBra3 === void 0 ? void 0 : _data$item7$targetBra3.inn : '–') + "/ " + _vm._s((_data$item8 = data.item) !== null && _data$item8 !== void 0 && (_data$item8$targetBra = _data$item8.targetBranch) !== null && _data$item8$targetBra !== void 0 && (_data$item8$targetBra2 = _data$item8$targetBra.entities) !== null && _data$item8$targetBra2 !== void 0 && (_data$item8$targetBra3 = _data$item8$targetBra2[0]) !== null && _data$item8$targetBra3 !== void 0 && _data$item8$targetBra3.kpp ? (_data$item9 = data.item) === null || _data$item9 === void 0 ? void 0 : (_data$item9$targetBra = _data$item9.targetBranch) === null || _data$item9$targetBra === void 0 ? void 0 : (_data$item9$targetBra2 = _data$item9$targetBra.entities) === null || _data$item9$targetBra2 === void 0 ? void 0 : (_data$item9$targetBra3 = _data$item9$targetBra2[0]) === null || _data$item9$targetBra3 === void 0 ? void 0 : _data$item9$targetBra3.kpp : '–') + " ")])])];
      }
    }, {
      key: "cell(sum)",
      fn: function (data) {
        var _data$value$toFixed, _data$value;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(((_data$value$toFixed = +((_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value.toFixed(2))) === null || _data$value$toFixed === void 0 ? void 0 : _data$value$toFixed.toLocaleString()) || 0) + " ₽")])];
      }
    }, {
      key: "cell(retail_sum)",
      fn: function (data) {
        var _data$value$toFixed2, _data$value2;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(((_data$value$toFixed2 = +((_data$value2 = data.value) === null || _data$value2 === void 0 ? void 0 : _data$value2.toFixed())) === null || _data$value$toFixed2 === void 0 ? void 0 : _data$value$toFixed2.toLocaleString()) || 0) + " ₽")])];
      }
    }, {
      key: "cell(retailSum)",
      fn: function (data) {
        var _data$value$toFixed3, _data$value3;

        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(((_data$value$toFixed3 = +((_data$value3 = data.value) === null || _data$value3 === void 0 ? void 0 : _data$value3.toFixed())) === null || _data$value$toFixed3 === void 0 ? void 0 : _data$value$toFixed3.toLocaleString()) || 0) + " ₽ ")])];
      }
    }, {
      key: "cell(operation)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.name ? data.item.name : '–') + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (data) {
        return [data.item.status === 'going' ? [_c('b-badge', [_vm._v("Собирается")])] : data.item.status === 'awaiting' ? [_c('b-badge', {
          staticClass: "warning"
        }, [_vm._v(" Ожидает подтверждение с другой стороны ")])] : data.item.status === 'confirm' ? [_c('b-badge', {
          staticClass: "success"
        }, [_vm._v("Подтвержден")])] : [_vm._v("Не определен")]];
      }
    }, {
      key: "cell(owner)",
      fn: function (data) {
        var _data$item$owner, _data$item$owner2;

        return [data.item.owner ? [_vm._v(" " + _vm._s((_data$item$owner = data.item.owner) === null || _data$item$owner === void 0 ? void 0 : _data$item$owner.first_name) + " " + _vm._s((_data$item$owner2 = data.item.owner) === null || _data$item$owner2 === void 0 ? void 0 : _data$item$owner2.last_name) + " ")] : [_vm._v("–")]];
      }
    }, {
      key: "cell(comment)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.comment ? data.item.comment : '-') + " ")];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }