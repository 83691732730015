<template>
  <div class="table-inner">
    <b-table
      id="table-docs"
      ref="table_docs"
      :fields="fields"
      :items="items"
      :busy="table_is_busy"
      :responsive="true"
      empty-filtered-text="Нет документов"
      show-empty
      class="table-docs"
      @row-clicked="rowClick"
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div class="center bold mt-2">Загрузка...</div>
        </div>
      </template>

      <template #head(id)>
        <e-checkbox
          :checked="getSelectAll"
          @click="changeSelectAll"
        />
      </template>

      <template #cell(id)="data">
        <div
          class="b-statuses"
          :class="{
            warning_confirm: data.item.status === 'confirm'
          }"
        ></div>

        <b-form-checkbox
          v-if="data.value"
          :checked="getSelectedArr?.some((el) => el === data?.item?.id)"
          class="cursor"
          @change="(val) => setSelect(val, data.item.id)"
        />
      </template>

      <template #cell(date)="data">
        <div class="b-date">{{ data.item.number }}</div>
        <div class="b-date date mt-1 table-substr">
          {{ data.item.date ? convertDate(data.item.date) : '' }}
        </div>
      </template>

      <template #cell(branch)="data">
        <div class="b-supplier name">
          {{ data.item.branch?.name ? data.item.branch?.name : 'Не указан' }}
        </div>
        <div class="b-supplier info table-substr">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item?.branch?.entities?.[0]?.inn ? data.item?.branch?.entities?.[0]?.inn : '–' }}/
            {{ data.item?.branch?.entities?.[0]?.kpp ? data.item?.branch?.entities?.[0]?.kpp : '–' }}
          </span>
        </div>
      </template>

      <template #cell(targetBranch)="data">
        <div class="b-supplier name">
          {{ data.item.targetBranch?.name ? data.item.targetBranch?.name : 'Не указан' }}
        </div>
        <div class="b-supplier info table-substr">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item?.targetBranch?.entities?.[0]?.inn ? data.item?.targetBranch?.entities?.[0]?.inn : '–' }}/
            {{ data.item?.targetBranch?.entities?.[0]?.kpp ? data.item?.targetBranch?.entities?.[0]?.kpp : '–' }}
          </span>
        </div>
      </template>

      <template #cell(sum)="data">
        <span class="d-flex float-right"> {{ (+data.value?.toFixed(2))?.toLocaleString() || 0 }} ₽</span>
      </template>

      <template #cell(retail_sum)="data">
        <span class="d-flex float-right"> {{ (+data.value?.toFixed())?.toLocaleString() || 0 }} ₽</span>
      </template>

      <template #cell(retailSum)="data">
        <span class="d-flex float-right"> {{ (+data.value?.toFixed())?.toLocaleString() || 0 }} ₽ </span>
      </template>

      <template #cell(operation)="data">
        {{ data.item.name ? data.item.name : '–' }}
      </template>

      <template #cell(status)="data">
        <template v-if="data.item.status === 'going'">
          <b-badge>Собирается</b-badge>
        </template>
        <template v-else-if="data.item.status === 'awaiting'">
          <b-badge class="warning"> Ожидает подтверждение с другой стороны </b-badge>
        </template>
        <template v-else-if="data.item.status === 'confirm'">
          <b-badge class="success">Подтвержден</b-badge>
        </template>
        <template v-else>Не определен</template>
      </template>

      <template #cell(owner)="data">
        <template v-if="data.item.owner"> {{ data.item.owner?.first_name }} {{ data.item.owner?.last_name }} </template>
        <template v-else>–</template>
      </template>

      <template #cell(comment)="data">
        {{ data.item.comment ? data.item.comment : '-' }}
      </template>

      <template #empty="scope">
        <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
      </template>
    </b-table>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    props: {
      fields: {
        type: Array,
        default: () => []
      },

      items: {
        type: Array,
        default: () => []
      },

      items_total: {
        type: Number,
        default: 0
      },

      table_is_busy: {
        type: Boolean,
        default: true
      },

      page_type: {
        type: String,
        default: ''
      }
    },

    data: function () {
      return {}
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getSelectedArr: 'movement/getSelectedArr',
        getSelectAll: 'movement/getSelectAll'
      })
    },

    watch: {
      items(newItems) {
        let currentSelected = newItems.filter((item) => {
          return this.getSelectedArr.some((el) => item.id === el.id)
        })
        if (currentSelected.length === 0 && this.items.length === 0) {
          this.SET_SELECT_ALL(false)
        } else if (currentSelected.length === this.items.length) {
          this.SET_SELECT_ALL(true)
        } else {
          this.SET_SELECT_ALL(false)
        }
      }
    },

    methods: {
      ...mapMutations({
        SET_SELECTED: 'movement/SET_SELECTED',
        SET_SELECT_ALL: 'movement/SET_SELECT_ALL'
      }),
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      changeSelectAll() {
        this.SET_SELECT_ALL(!this.getSelectAll)
        if (!this.getSelectAll) {
          let select = this.getSelectedArr
          this.items.forEach((obj) => (select = select.filter((item) => obj.id !== item)))
          this.SET_SELECT_ALL(this.getSelectAll)
          this.SET_SELECTED(select)
          return
        }

        let arrayCurrenSelected = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.getSelectedArr.some((obj) => obj === id)) {
            arrayCurrenSelected.push(id)
          }
        })
        this.SET_SELECTED([...this.getSelectedArr, ...arrayCurrenSelected])
      },

      rowClick(item) {
        this.$router.push({
          name: `operation-process.overhead.movement.document.edit`,
          params: { id: item.id }
        })
      },
      setSelect(val, idItem) {
        if (val) this.SET_SELECTED([...this.getSelectedArr, idItem])
        else {
          const select = this.getSelectedArr?.filter((el) => el !== idItem)
          this.SET_SELECTED(select)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.getSelectedArr.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.SET_SELECT_ALL(false)
        if (currentSeleted.length === this.items.length) this.SET_SELECT_ALL(true)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;
    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }
  :deep .table-docs {
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;
    .b-statuses {
      width: 2px;
      height: 32px;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
      &.warning {
        background: #ffa800;
      }
    }
    th.center:not(:nth-child(6)) {
      position: relative;
    }
    table {
      border-top: none;
      border-collapse: separate;
      border-spacing: 0;
      tbody {
        tr:first-child {
          th {
            border-top: none;
          }
        }
      }
      tr:not(:nth-child(1)) {
        td {
          border-top: 1px solid #e1e1e1 !important;
        }
      }
    }
    tr {
      cursor: pointer;
    }
    thead {
      top: 0;
      background-color: #fff;
      position: sticky;
      z-index: 100;
      th {
        border-bottom: 1px solid #e1e1e1 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bcbcbc;
    }
    th.center {
      position: relative;
      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
  }

  .warning_confirm {
    background-color: #00cb91 !important;
  }

  .table-inner {
    display: flex;
    flex: 1;
  }

  @media (max-height: 700px) {
    .table-docs {
      max-height: 100% !important;
    }
  }
</style>
