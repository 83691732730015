<template>
  <section id="movement-documents">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        :page_type="page_type"
        @date_range="updateDateRange"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="doc_table_filter"
          table_name="movement-docs"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <documents-table
          :fields="fields"
          :items_total="items_total"
          :items="document_list"
          :table_is_busy="tableIsBusy"
          :page_type="page_type"
          @select="setSelected"
        />
      </div>
      <table-navbar
        :items="getSelectedArr"
        items_name="documents"
        doc_type="movement"
        :clear_selected="clearSelected"
        @remove_items="remove_items"
      />
      <pagination

        :total="items_total"
        table_name="movement-docs"
        @change_pagination="handlerPagination"
      />
    </div>
  </section>
</template>

<script>
  import DocumentsHeader from '../../components/DocumentsHeader'
  import DocumentsTableFilter from '../../components/DocumentsTableFilter'
  import DocumentsTable from '../../components/movement/DocumentsTable'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as R from 'ramda'

  export default {
    components: {
      DocumentsHeader,
      DocumentsTableFilter,
      DocumentsTable,
      Pagination,
      TableNavbar
    },

    apollo: {
      MovementHeads: {
        query: require('../../gql/getMovementHeads.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id,
            documentPagination: {
              period: {
                begin: this.data_range[0] ?? null,
                end: this.data_range[1] ?? null
              },
              skip: this.getPagination['movement-docs'].skip,
              take: this.getPagination['movement-docs'].take,
              search: this.getPagination['movement-docs'].search
            }
          }
        },
        result({ data }) {
          this.SET_SELECTED([])
          this.items_total = R.pathOr(0, ['MovementHeads', 'total'], data)
          this.document_list = R.pathOr([], ['MovementHeads', 'documents'], data)

          this.tableIsBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        },
        skip() {
          return this.$route.meta.type !== 'movement'
        },
        watchLoading(isLoading) {
          this.tableIsBusy = isLoading
        }
      }
    },

    data: function () {
      return {
        tableIsBusy: true,
        title: this.$route.meta.title,
        page_type: this.$route.meta.type,
        items_total: 0,
        data_range: [],
        document_list: [],
        isKey: 0,
        fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center',
            thStyle: 'min-width: 48px'
          },
          {
            key: 'date',
            label: 'Дата',
            thStyle: 'min-width: 131px',
            sortable: true
          },
          {
            key: 'branch',
            label: 'Отправитель',
            thStyle: 'min-width: 300px',
            sortable: true
          },
          {
            key: 'targetBranch',
            label: 'Получатель',
            sortable: true,
            thStyle: 'min-width: 300px'
          },
          {
            key: 'sum',
            label: 'Итого по док-ту (₽)',
            sortable: true,
            class: 'center',
            thStyle: 'min-width: 192px'
          },
          {
            key: 'status',
            label: 'Статус',
            thStyle: 'min-width: 188px',
            class: 'center'
          },
          {
            key: 'owner',
            label: 'Менеджер',
            thStyle: 'min-width: 188px'
          },
          {
            key: 'comment',
            label: 'Комментарий',
            thStyle: 'min-width: 200px'
          }
        ]
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination',
        getSelectedArr: 'movement/getSelectedArr',
        getSelectAll: 'movement/getSelectAll'
      })
    },

    updated() {
      this.page_type = this.$route.meta.type
    },

    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      setSelected(data) {
        this.SET_SELECTED(data)
      },
      ...mapMutations({
        SET_SELECTED: 'movement/SET_SELECTED',
        SET_SELECT_ALL: 'movement/SET_SELECT_ALL'
      }),

      clearSelected() {
        this.SET_SELECTED([])
        this.SET_SELECT_ALL(false)
      },
      updateDateRange(date) {
        this.data_range = date
        this.$apollo.queries.MovementHeads.refresh()
      },

      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['movement-docs']: {
            ...this.getPagination?.['movement-docs'],
            search
          }
        })
      },

      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['movement-docs']: {
              ...this.getPagination?.['movement-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['movement-docs']: {
              ...this.getPagination?.['movement-docs'],
              take
            }
          })
        }
      },

      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['movement-docs']: {
            ...this.getPagination?.['movement-docs'],
            search: ''
          }
        })
      },

      remove_items(items) {
        this.$apollo
          .mutate({
            mutation: require('../../gql/RemoveMovementDocument.graphql'),
            variables: {
              ids: items
            }
          })
          .then(() => {
            this.$apollo.queries.MovementHeads.refetch()
            this.SET_SELECT_ALL(false)
            this.SET_SELECTED([])
            this.$noty.show(`Удалено`)
          })
          .catch((err) => {
            console.log(err)
            this.$noty.show(`Что-то пошло не так.`)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #movement-documents {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
